import React from 'react';
import ContentCarousel from '@clearscore/ui.rainbow.content-carousel';
import CardTemplateE from '@clearscore/ui.rainbow.card-template-e';
import Tag from '@clearscore/ui.rainbow.tag';
import Text from '@clearscore/ui.rainbow.text';

import styles from './mobile-card-carousel.module.css';

export interface IMobileCardCarouselProps {
    cards: Array<{ tag: string; title: string; footer: string; image: string }>;
}

export const MobileCardCarousel = ({ cards = [] }: IMobileCardCarouselProps): React.ReactElement => (
    <ContentCarousel
        maxCarouselItems={{
            ALL: 1,
            PHABLET: 2,
            HUGE: 3,
        }}
        maxInlineItems={{
            ALL: 1,
            PHABLET: 2,
            HUGE: 3,
        }}
    >
        {cards.map(({ tag, title, footer, image }, key) => (
            <div className={styles.wrapper} key={key}>
                <CardTemplateE
                    title={<Text.H2 tag="p">{title}</Text.H2>}
                    footerText={<Text.Body4 tag="p">{footer}</Text.Body4>}
                    tag={<Tag displayState={Tag.displayState.PRIMARY}>{tag}</Tag>}
                    cornerImageSrc={image}
                />
            </div>
        ))}
    </ContentCarousel>
);

export default MobileCardCarousel;
