import type { ReactElement } from 'react';
import React from 'react';
import Card from '@clearscore/ui.rainbow.card';
import Information from '@clearscore/ui.rainbow.information';
import cx from 'classnames';

import TrustpilotStars from './lib/trustpilot-stars.svg';
import styles from './trustpilot-card.module.css';

export interface CardTrustpilotProps {
    name: string;
    review: string;
    date?: string;
    onClick?: () => void;
}

const TrustpilotCard = ({
    name = 'Customer',
    review,
    date,
    onClick = undefined,
}: CardTrustpilotProps): ReactElement => (
    <Card onClick={onClick} borderRadius={Card.BorderRadius.Medium}>
        <Card.ContentContainer sectionPosition={Card.SectionPosition.VERTICAL}>
            <Card.ContentPrimary>
                <div data-qa="card-trustpilot-content">
                    <div className={cx(styles.header)} data-qa="card-trustpilot-header">
                        <TrustpilotStars width={96} height={16} />
                    </div>

                    <Information title={name} description={review} />
                </div>
            </Card.ContentPrimary>

            {date ? (
                <div className={styles.footer}>
                    <div className={styles.footerDivider} />
                    <Card.ContentSecondary padding="none">{date}</Card.ContentSecondary>
                </div>
            ) : null}
        </Card.ContentContainer>
    </Card>
);

export default TrustpilotCard;
