import type { FC } from 'react';
import React, { Fragment } from 'react';
import Card from '@clearscore/ui.rainbow.card';
import cx from 'classnames';

import type { ICardTemplateEProps, ICardTemplateEExports } from './types';
import styles from './card-template-e.module.css';

const CardTemplateE: FC<ICardTemplateEProps> & ICardTemplateEExports = ({
    tag,
    label,
    title,
    subtitle,
    footerText,
    cornerImageSrc,
    displayState,
    href,
    ariaLabel,
    borderRadius = Card.BorderRadius.Large,
    padding = Card.Padding.MEDIUM,
    onClick,
}) => (
    <Card borderRadius={borderRadius} href={href} ariaLabel={ariaLabel} onClick={onClick}>
        <Card.ContentContainer>
            <Card.ContentPrimary displayState={displayState} padding={padding} offsetImage>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div>{tag}</div>

                        {cornerImageSrc ? (
                            <img
                                src={cornerImageSrc}
                                className={styles['corner-image']}
                                alt=""
                                data-qa="corner-image"
                            />
                        ) : null}
                    </div>

                    <div
                        className={cx(
                            styles['title-container'],
                            styles[`title-container--display-state-${displayState}`],
                        )}
                    >
                        {label ? <span className={styles['label-container']}>{label}</span> : null}
                        {title}
                        {subtitle ? <div className={styles['subtitle-container']}>{subtitle}</div> : null}
                        {footerText ? (
                            <Fragment>
                                <hr
                                    className={cx(styles.overline, styles[`overline--display-state-${displayState}`])}
                                />
                                <div>{footerText}</div>
                            </Fragment>
                        ) : null}
                    </div>
                </div>
            </Card.ContentPrimary>
        </Card.ContentContainer>
    </Card>
);

CardTemplateE.BorderRadius = Card.BorderRadius;
CardTemplateE.Padding = Card.Padding;
CardTemplateE.DisplayState = Card.DisplayState;

export default CardTemplateE;
