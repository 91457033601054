import React from 'react';
import type { ComponentProps } from 'react';
import Trustpilot from '@clearscore/shared.website-trustpilot';
import TrustpilotCard from '@clearscore/shared.website-trustpilot-card';
import ContentCarousel from '@clearscore/ui.rainbow.content-carousel';

import styles from './trustpilot-carousel.module.css';

export interface ITrustpilotCarouselProps {
    cards: ComponentProps<typeof TrustpilotCard>[];
}

const TrustpilotCarousel = ({ cards }: ITrustpilotCarouselProps): React.ReactElement => (
    <div className={styles.component}>
        <div className={styles.trustpilot}>
            <Trustpilot />
        </div>
        <ContentCarousel
            maxCarouselItems={{
                ALL: 1,
                PHABLET: 2,
                MEDIUM: 3,
            }}
            maxInlineItems={{
                ALL: 1,
                PHABLET: 2,
                MEDIUM: 3,
            }}
        >
            {cards.map((card, index) => (
                <div className={styles.card} key={index}>
                    <TrustpilotCard {...card} />
                </div>
            ))}
        </ContentCarousel>
    </div>
);

export default TrustpilotCarousel;
