import type { ReactElement, ReactNode, SVGProps, ElementType } from 'react';
import React from 'react';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import toTitleCase from '@clearscore-group/lib.normalize.title-case';

import styles from './list.module.css';

export const Spacing = {
    TINY: 'TINY',
    LARGE: 'LARGE',
} as const;

interface IHeadingProps {
    children: ReactNode;
}

type Heading = (props: IHeadingProps) => ReactElement;

const Heading: Heading = ({ children }) => <Text.H3>{children}</Text.H3>;

interface ITitleProps {
    children: ReactNode;
}

interface Title {
    (props: ITitleProps): ReactElement;
}

const Title: Title = ({ children }) => <dt className={styles.dt}>{children}</dt>;

interface IDescriptionProps {
    children: ReactNode;
}

type Description = (props: IDescriptionProps) => ReactElement;
const Description: Description = ({ children }) => <dd className={styles.dd}>{children}</dd>;

export type Icon = (props: SVGProps<SVGElement>) => ReactElement;

interface IItemProps {
    children: ReactNode;
    bulletIcon?: Icon;
    className?: string;
}

export type Item = (props: IItemProps) => ReactElement;

const Item: Item = ({ children, bulletIcon: ItemIcon, className }) => (
    <li className={cx(styles.li, className)}>
        {ItemIcon ? (
            <span className={styles.bulletIcon}>
                <ItemIcon width={24} height={24} />
            </span>
        ) : null}
        {children}
    </li>
);

interface IIconProps {
    children: Icon;
}

type IconSVG = (props: IIconProps) => ReactElement;

const Icon: IconSVG = ({ children: Child }) => (
    <span className={styles.icon}>
        <Child height={24} width={24} />
    </span>
);

interface IChildTypes {
    Title: ReactNode[];
    Description: ReactNode[];
    Item: ReactNode[];
    Heading: ReactNode[];
    Icon: ReactNode[];
}

interface IValidateChildReturn {
    ChildTypes: IChildTypes;
    Children: ReactNode;
}

type DisplayName = 'Title' | 'Description' | 'Item' | 'Heading' | 'Icon';

const validateChildTypes = (children: ReactNode): IValidateChildReturn => {
    const ChildTypes: IChildTypes = { Title: [], Description: [], Item: [], Heading: [], Icon: [] };
    const Children: ReactNode[] = [];
    React.Children.forEach(children, (child: ReactNode) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const displayName: DisplayName = child?.type?.displayName || child?.type.name;
        if (ChildTypes[displayName]) {
            ChildTypes[displayName].push(child);
        }

        if (!['Heading', 'Icon'].includes(displayName)) {
            Children.push(child);
        }
    });

    return { ChildTypes, Children };
};

const getTag = (childTypes: IChildTypes, isNumbered: boolean | undefined): ElementType => {
    if (childTypes.Description.length || childTypes.Title.length) {
        return 'dl';
    } else if (isNumbered) {
        return 'ol';
    }
    return 'ul';
};

export interface IListProps {
    children: ReactNode;
    heading?: string;
    ariaLabel?: string;
    isNumbered?: boolean;
    hasBullet?: boolean;
    icon?: () => ReactElement;
    spacing?: (typeof Spacing)[keyof typeof Spacing];
    dataId?: string;
    tag?: string;
}

export interface IListExports {
    Heading: typeof Heading;
    Icon: typeof Icon;
    Title: typeof Title;
    Description: typeof Description;
    Item: typeof Item;
    Spacing: typeof Spacing;
}

type List = (props: IListProps) => ReactElement;

const List: List & IListExports = ({
    children,
    heading = null,
    ariaLabel,
    isNumbered = false,
    hasBullet = true,
    icon = null,
    spacing = null,
    dataId,
    tag = null,
}) => {
    const { ChildTypes, Children } = validateChildTypes(children);
    const Tag = tag || getTag(ChildTypes, isNumbered);
    return (
        <div className={cx({ [styles[`spacing${toTitleCase(spacing)}`]]: !!spacing })} data-qa="list" data-id={dataId}>
            <div id={styles.heading} className={styles.heading}>
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                <React.Fragment>
                    {icon && <Icon>{icon}</Icon>}
                    {ChildTypes.Icon}
                    {heading && <Heading>{heading}</Heading>}
                    {ChildTypes.Heading}
                </React.Fragment>
            </div>
            <Tag
                className={cx(styles.list, { [styles.isNumbered]: isNumbered, [styles.hasBullet]: hasBullet })}
                aria-label={ariaLabel}
                aria-describedby={heading ? styles.heading : undefined}
            >
                {Children}
            </Tag>
        </div>
    );
};

List.Heading = Heading;
List.Icon = Icon;
List.Title = Title;
List.Description = Description;
List.Item = Item;
List.Spacing = Spacing;

export default List;
