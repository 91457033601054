import type { ReactElement } from 'react';
import React from 'react';
import TwoPanelSection from '@clearscore/shared.website-two-panel-section';
import Section from '@clearscore/shared.website-section';
import TrustpilotCarousel from '@clearscore/shared.website-trustpilot-carousel';
import Template from '@clearscore/shared.website-template';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';
import List from '@clearscore/ui.rainbow.list';
import Text from '@clearscore/ui.rainbow.text';
import Stack from '@clearscore/ui.rainbow.stack';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import useMedia from '@clearscore-group/lib.hooks.use-media';
import { mediaQuery } from '@clearscore/ui.rainbow.tokens';
import type { IMobileCardCarouselProps } from '@clearscore/shared.website-mobile-card-carousel';
import MobileCardCarousel from '@clearscore/shared.website-mobile-card-carousel';
import GridContainer from '@clearscore/ui.rainbow.grid-container';
import type { ILogoGridProps } from '@clearscore/shared.logo-grid';
import LogoGrid from '@clearscore/shared.logo-grid';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Button from '@clearscore/ui.rainbow.button';
import SuccessTick from '@clearscore/rainbow.icons.success-tick';

import styles from './logged-out-offers-landing.module.css';
import type { LOGGED_OUT_OFFERS_CARDS, LOGGED_OUT_OFFERS_LOGOS } from './lib/consts';
import { COMPONENT_NAMESPACE } from './lib/consts';

const APPLY_LINK = `/find/balance-transfer-credit-cards/apply/`;

export interface LoggedOutOffersLandingProps {
    appStoreConfig: {
        googlePlayUrl: string;
        appStoreUrl: string;
        appStoreLink: string;
        playStoreLink: string;
        appleStoreToggle: boolean;
        googleStoreToggle: boolean;
    };
    cookiePolicyConfig: {
        moreInfoLinkUrl: string;
        enabled: boolean;
        language: Record<string, string>;
    };
    backgroundImage: IGatsbyImageData;
    cardImages: Record<LOGGED_OUT_OFFERS_CARDS, IGatsbyImageData>;
    logoImages: Record<LOGGED_OUT_OFFERS_LOGOS, IGatsbyImageData>;
}

const LoggedOutOffersLanding = (props: LoggedOutOffersLandingProps): ReactElement => {
    const { appStoreConfig, cookiePolicyConfig, backgroundImage, cardImages, logoImages } = props;
    const { t } = useTranslation(COMPONENT_NAMESPACE);

    const cards: IMobileCardCarouselProps['cards'] = Object.keys(cardImages).map((key) => ({
        ...t(`cards.${key}`),
        image: cardImages[key].images.fallback.src,
    }));

    const logos: ILogoGridProps['logos'] = Object.keys(logoImages).map((key) => ({
        ...t(`logos.${key}`),
        gatsbyImageData: logoImages[key],
        image: logoImages[key],
    }));

    const isPhablet = useMedia([null, mediaQuery['rnb-mq-medium']], [true, false]);

    return (
        <Template
            appStoreConfig={appStoreConfig}
            cookiePolicyConfig={cookiePolicyConfig}
            pullUpContent
            navigationConfig={{
                style: 'MINIMAL',
            }}
            footerConfig={{
                style: 'MINIMAL',
            }}
        >
            <div className={styles.component}>
                <TwoPanelSection
                    bgImage={{
                        image: {
                            gatsbyImageData: backgroundImage,
                        },
                        imageDesktop: {
                            gatsbyImageData: backgroundImage,
                        },
                    }}
                    childrenTop={
                        <Stack all={Stack.spacings.LARGE}>
                            <Text.H1 className={styles.title}>
                                {interpolateWithComponents(t('header'), [
                                    {
                                        Component: 'span',
                                        props: { className: styles.asterisk },
                                    },
                                ])}
                            </Text.H1>

                            <Text.H3 className={styles.subtitle}>{t('subheader')}</Text.H3>

                            <List spacing="LARGE">
                                {Array.from(Object.values(t('bullets'))).map((bullet) => (
                                    <List.Item className={styles.listItem} bulletIcon={SuccessTick}>
                                        <div className={styles.listItemWrapper}>{bullet}</div>
                                    </List.Item>
                                ))}
                            </List>

                            <Button
                                type="PRIMARY"
                                theme="DARK"
                                size="LARGE"
                                isResponsive
                                isNarrow={!isPhablet}
                                onClick={() => {
                                    window.location.pathname = APPLY_LINK;
                                }}
                            >
                                {t('cta')}
                            </Button>

                            <div className={styles.cardsWrapper}>
                                <MobileCardCarousel cards={cards} />
                            </div>

                            <small>
                                <Text.Caption tag="p">{t('legal')}</Text.Caption>
                            </small>
                        </Stack>
                    }
                />

                <Section backgroundColor="light">
                    <TrustpilotCarousel cards={Object.values(t('reviews'))} />
                </Section>

                <GridContainer>
                    <div className={styles.spanFullWidth}>
                        <Spacer
                            all={{
                                top: Spacer.spacings.LARGE,
                                bottom: Spacer.spacings.MEDIUM,
                            }}
                            phablet={{
                                bottom: Spacer.spacings.LARGE,
                            }}
                        >
                            <LogoGrid heading={null} logos={logos} />
                        </Spacer>
                    </div>
                </GridContainer>
            </div>
        </Template>
    );
};
export default LoggedOutOffersLanding;
