import type { ReactElement } from 'react';
import React from 'react';
import { graphql, type PageProps } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import LoggedOutOffersLanding from '../components/verticals/logged-out-offers-landing';
import Layout from '../components/layout';

const LoggedOutOffersLandingTemplate = (props: PageProps<Queries.LoggedOutOffersLandingQuery>): ReactElement => {
    const { location, data } = props;

    return (
        <Layout
            meta={{
                title: 'ClearScore | Balance Transfer Credit Cards | ClearScore GB',
                description:
                    'Find out which credit cards you could get accepted for, without harming your credit score',
                robots: 'noindex, nofollow',
            }}
            location={location.pathname}
        >
            {({ commonModules }): ReactElement => (
                <LoggedOutOffersLanding
                    backgroundImage={getImage(data.backgroundImage)}
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    cardImages={{
                        'card-santander': getImage(data.cardSantander),
                        'card-tesco': getImage(data.cardTesco),
                        'card-virgin': getImage(data.cardVirgin),
                    }}
                    logoImages={{
                        'logo-natwest': getImage(data.logoNatwest),
                        'logo-virgin': getImage(data.logoVirgin),
                        'logo-capital': getImage(data.logoCapital),
                        'logo-halifax': getImage(data.logoHalifax),
                        'logo-lloyds': getImage(data.logoLloyds),
                    }}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query LoggedOutOffersLanding {
        backgroundImage: file(absolutePath: { regex: "/gb/logged-out-offers/leaves-background.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        cardSantander: file(absolutePath: { regex: "/gb/logged-out-offers/cards/card-santander.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        cardTesco: file(absolutePath: { regex: "/gb/logged-out-offers/cards/card-tesco.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        cardVirgin: file(absolutePath: { regex: "/gb/logged-out-offers/cards/card-virgin.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        logoNatwest: file(absolutePath: { regex: "/gb/logged-out-offers/logos/logo-natwest.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        logoVirgin: file(absolutePath: { regex: "/gb/logged-out-offers/logos/logo-virgin.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        logoCapital: file(absolutePath: { regex: "/gb/logged-out-offers/logos/logo-capital.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        logoHalifax: file(absolutePath: { regex: "/gb/logged-out-offers/logos/logo-halifax.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        logoLloyds: file(absolutePath: { regex: "/gb/logged-out-offers/logos/logo-lloyds.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
    }
`;

export default LoggedOutOffersLandingTemplate;
