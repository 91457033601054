import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import toTitleCase from '@clearscore-group/lib.normalize.title-case';

import styles from './information.module.css';
import { Theme, Alignment } from './constants';

export interface InformationProps {
    title: string;
    dataId?: string;
    description?: string;
    theme?: (typeof Theme)[keyof typeof Theme];
    align?: (typeof Alignment)[keyof typeof Alignment];
}

export interface InformationExport {
    (props: InformationProps): ReactElement;
    Theme: typeof Theme;
    Alignment: typeof Alignment;
}

const Information: InformationExport = ({
    title,
    dataId,
    description,
    theme = Theme.LIGHT,
    align = Alignment.START,
}) => {
    const themeClass = styles[`isTheme${toTitleCase(theme)}`];
    const alignClass = styles[`isAlign${toTitleCase(align)}`];

    return (
        <div data-qa="information" data-id={dataId} className={cx(styles.component, alignClass)}>
            <div className={cx(styles.title, themeClass)} data-qa="information-title">
                <Text.H3>{title}</Text.H3>
            </div>
            {description ? (
                <div className={cx(styles.description, themeClass)} data-qa="information-description">
                    <Text.Body2>{description}</Text.Body2>
                </div>
            ) : null}
        </div>
    );
};

Information.Theme = Theme;
Information.Alignment = Alignment;

export default Information;
