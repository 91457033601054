import { EN_GB } from '@clearscore/config.i18n';
import i18next from 'i18next';

import LoggedOutOffersLanding from './logged-out-offers-landing';
import { COMPONENT_NAMESPACE } from './lib/consts';
import copyEnGb from './copy/en-gb/copy.json';

i18next.addResourceBundle(EN_GB, COMPONENT_NAMESPACE, copyEnGb);

export default LoggedOutOffersLanding;
