export const Theme = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
} as const;

export const Alignment = {
    START: 'start',
    CENTER: 'center',
    END: 'end',
} as const;
